<template>
  <div id="app" align="center">
    <h1>欢迎来到博客后台管理中心，请开始你的旅程!</h1>
    <el-container>
      <div class="main-pic" align="middle" style="margin:auto">
        <img src="../../assets/images/71f294ddb4a4984817cb5c6c1e3e6102.jpg" class="main-pic" width="400" height="400" align="middle">
      </div>
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
}
</script>

<style scoped>

</style>
