<template>
  <div class="about">
    <div class="about-banner banner">
      <h1 class="banner-title">关于</h1>
    </div>
    <!--中间内容-->
    <div  class="m-container m-about">
      <div class="ui container">
          <div class="youce">
            <div class="ui top attached segment my-shadow">
              <div class="ui header">关于我</div>
            </div>
            <div class="ggg" style="display: flex; ">
              <!-- <home ref="home"/> -->
               <!-- 关于项目的部分 -->
               <!-- 这个部分直接调用博客的id渲染到页面上去 -->
               <!-- 重载 -->
                  <card class="card-x" @click="toBlog('1796921738281025537')"> <h1> 关于这个网站的信息介绍></h1></card>
               <!-- 关于我 -->
                  <card class="card-x" @click="toBlog('1797131204012212226')"> <h1>关于我本人></h1></card>
            </div>
            <div class="ui attached segment my-shadow">
              <div class="ui orange basic left pointing label">长板(菜且爱玩)</div>
              <div class="ui orange basic left pointing label">动漫并中二</div>
              <div class="ui orange basic left pointing label">画画</div>
              <div class="ui orange basic left pointing label">编程</div>
              <div class="ui orange basic left pointing label">写小说</div>
              <div class="ui orange basic left pointing label">思考人生</div>
            </div>
            <div class="ui attached segment my-shadow">
              <div class="ui my-blue basic left pointing label m-margin-tb-tiny">spring</div>
              <div class="ui my-blue basic left pointing label m-margin-tb-tiny">Java</div>
              <div class="ui my-blue basic left pointing label m-margin-tb-tiny">JavaScript</div>
              <div class="ui my-blue basic left pointing label m-margin-tb-tiny">python</div>
              <div class="ui my-blue basic left pointing label m-margin-tb-tiny">Node</div>
              <div class="ui my-blue basic left pointing label m-margin-tb-tiny">vue</div>
              <div class="ui my-blue basic left pointing label m-margin-tb-tiny">rabbitMQ</div>
              <div class="ui my-blue basic left pointing label m-margin-tb-tiny">ps</div>
               <div class="ui my-blue basic left pointing label m-margin-tb-tiny">为威化饼干献出心脏</div>
              <div class="ui my-blue basic left pointing label m-margin-tb-tiny">...</div>
            </div>
            <div class="ui bottom attached segment my-shadow">
              <a class="ui wangyiyun circular icon button" data-position="bottom center" @click="jump1"><img src="../../assets/images/wangyiyun-copy.svg" style="width:15px;height:15px"></a>
              <a class="ui bilibili circular icon button" data-position="bottom center" @click="jump2"><img src="../../assets/images/bilibili.svg" style="width:15px;height:15px"></a>
              <a class="ui github circular icon button" data-content="https://github.com/xuyangwei" data-position="bottom center"><i class="github icon"></i></a>
              <a class="ui wechat circular icon button"><i class="weixin icon"></i></a>
              <a class="ui qq circular icon button" data-content="429303349" data-position="bottom center"><i class="qq icon"></i></a>
            </div>

            <div class="ui wechat-qr flowing popup transition hidden">
              <!-- 这里是微信二维码图片 ，到时候也可以删除这个-->
              <!-- <img src="" alt="" class="ui rounded image" style="width: 110px"> -->
            </div>
          </div>

      </div>
    </div>

    <div id="toolbar" class="m-padded m-fixed m-right-bottom" style="display: none">
      <div class="ui vertical icon buttons ">
        <button type="button" class="ui toc my-blue button" >目录</button>
        <a href="#comment-container" class="ui my-blue button" >留言</a>
        <button class="ui wechat icon button"><i class="weixin icon"></i></button>
        <div id="toTop-button" class="ui icon button" ><i class="chevron up icon"></i></div>
      </div>
    </div>

    <div class="ui toc-container flowing popup transition hidden" style="width: 250px!important;">
      <ol class="js-toc">

      </ol>
    </div>

    <div id="qrcode" class="ui wechat-qr flowing popup transition hidden " style="width: 130px !important;">
      <!--<img src="./static/images/wechat.jpg" alt="" class="ui rounded image" style="width: 120px !important;">-->
    </div>
    <br>
    <br>

  </div>

</template>

<script>
// import home from '../Home';

export default {
  methods: {
    jump1 () {
      window.location.href = "https://music.163.com/#/user/home?id=3339781094";
    },
    jump2 () {
      window.location.href = "https://space.bilibili.com/455043741?spm_id_from=333.788.0.0";
    },
    // guanyuxiangmu (blogId) {
    //   this.$refs.home.toBlog(blogId);
    // },
    // guanyuwo (blogId) {
    //   this.$refs.home.toBlog(blogId);
    // }
    // xiangmu () {
    //   this.$router.push({ path: "/TT" });
    // }
    toBlog (blogId) {
      this.$store.state.blogId = blogId;
      this.$router.push({ path: "/blog/" + blogId });
    }
  },
  data () {
    return {
      user: {},
      nickname: '',
      // 被激活的链接地址
      avatar: ''
    }
  },
  mounted () {
    $('.menu.toggle').click(function () {
      $('.m-item').toggleClass('m-mobile-hide')
    })
    $('.ui.dropdown').dropdown({
      on: 'hover'
    })
    $('.wechat').popup({
      popup: $('.wechat-qr'),
      position: 'bottom center'
    })
    $('.qq').popup()
    $('.github').popup()
  }
}
</script>

<style scoped>
 .about-banner {
   height: 500px;
   background: url(https://wwaffle.oss-cn-beijing.aliyuncs.com/blog/htmlbanner/Screenshot_2024-04-20-12-05-07-033_com.alicloud.databox.jpg) center
   center / cover no-repeat;
   background-color: #49b1f5;}
 .container{
   animation: main 1.0s;
 }
 .m-about {
   padding-top: 450px !important;
   padding-bottom: 0 !important;
 }
 .card-x{
   margin: 20px;
   width: 45%;
   height: 200px;
  transition: transform 0.3s ease-in-out;
  will-change: transform;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

}
h1{
  color:black;
}
</style>
